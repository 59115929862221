export default {
  COMMON: {
    ENV: 'production',
    RELEASE_DATE: '20250401010808',
    BASE_URL: 'https://magazine.nikkei.com',
    REACT_SERVER_PORT: 3000,
    GQL_SERVER_PORT: 4000,
    GQL_SERVER_URL: 'http://nodejs-server:4000',
    PASS_PATH: "/api",
    DESCRIPTION: "「THE NIKKEI MAGAZINE」はポストコロナ時代に輝きを放つ「真のラグジュアリー」を探求し、今こそ実践したい装いと生活スタイルをビジネスパーソンに提案する、日本経済新聞社のサイトです。"
  },
  LINK: {
    NIKKEI_URL: "https://www.nikkei.com",
    ABOUT_NIKKEI_URL: "/info/about.html",
    SITE_EXEMPTION_URL: "https://www.nikkei.com/info/exemption.html",
    SITE_COPYRIGHT_URL: "https://www.nikkei.com/info/copyright.html",
    LINK_POLICY_URL: "https://www.nikkei.com/info/link.html",
    COOKIE_POLICY_URL: "https://www.nikkei.com/lounge/privacy/cookie/policy.html",
    COOKIE_OPTOUT_URL: "https://www.nikkei.com/lounge/privacy/cookie/optout.html#nikkeimagazine",
    INFO_TOS_URL: "/info/toc.html",
    ABOUT_PRIVACY_URL: "https://www.nikkei.com/lounge/privacy/privacy.html",
    ABOUT_MEDIA_URL: "https://marketing.nikkei.com/media/web/",
    ABOUT_HELP_URL: "https://www.nikkei.com/help/",
    ABOUT_ANSWERS_URL: "https://support.nikkei.com/app/ask_0101/p/460",
    ABOUT_AI_ANSWERS_URL: "https://enq.kodansha.co.jp/?uecfcode=enq-yadobe-63",
    SHARE_INSTAGRAM_URL: "https://www.instagram.com/thenikkeimagazine",
    SHARE_FACEBOOK_URL: "https://www.facebook.com/thenikkeimagazine",
    SHARE_TWITTER_URL: "https://twitter.com/NIKKEI_MAGAZINE",
    SHARE_YOUTUBE_URL: "https://www.youtube.com/@thenikkeimagazine",
    SHARE_PINTEREST_URL: "https://jp.pinterest.com/thenikkeimagazine/",
    MAIL_MAGAZINE_URL: "https://idrp.nikkei.com/reg/ai/frauth/start/",
    AI_URL: "/ai",
    NOTICE_URL: "/notification",
    SITE_IMAGE: "/images/ogp.jpg"
  },
  ID: {
    AI: 'D0000009',
    SLIDER: 'D0000022',
    PUSHLIST: 'D0000023',
    NEWS: 'D0000024',
    PROMOTION: 'D0000025',
    TOPICS: 'D0000026',
    INFORMATION: 'D0000027',
    EXCLUSION_RANKING: ['D0000007'],
    OPERATION_TAG_TU: 'D103',
    OPERATION_TAG_RTU: 'D104',
    NOTICE_TAG: [['D110'],['D105','D110'],['D106','D110'],['D110'],['D110']],
    EXCHANGE_TAG: {'D000001Y':'ACH03000'},
  },
  LIMIT: {
    META_ARTICLE_DESCRIPTION_LIMIT: 120,
    THREE_DOTS: "…",
    THREE_DOTS_LENGTH: 2,
    TAG_COUNT: 20
  },
  REACT: {
    SESSION_STORE_HOST: 'prod-cacheclu.tnm.local',
    SESSION_STORE_PORT: 6379,
    TNM_CONNECT: 'TNM-CONNECT',
    NID_AUTHENTICATED: 'NID-AUTHENTICATED',
    NID_AUTOLOGIN: 'NID-AUTOLOGIN',
    NID_SERIA_COOKIE: 'NID-Serial-Cookie',
    AUTH_IDRP_IGNORE_SSL_VERIFY: false,
    AUTH_CLIENT_ID: 'AI',
    AUTH_CLIENT_SECRET: 'wSZEBbuy',
    AUTH_SITE_ID: 'ai',
    AUTH_SITE_PASS: 'AiGlHGDGx576HmJRYCsHJpClyX4qLpSs',
    AUTH_SCOPE: 'openid profile_nid',
    AUTH_EXPIRATION_DATE: '60',
    AUTH_PXEP: '',
    AUTH_NID_BASE_URL: 'https://id.nikkei.com',
    AUTH_IDRP_BASE_URL: 'https://idrp.nikkei.com',
  }
}
