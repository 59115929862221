import React, { useContext, useRef, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { gql, useMutation } from "@apollo/client";
import { v4 as uuidv4 } from 'uuid';

import { generateNikkeiIdMemberLoginUrl, reqClearKbn } from '../../../utils/Authentication';
import { UserContext } from "../../../contexts/UserContext";
import { FavoriteContext } from "../../../contexts/FavoriteContext";
import { QueryStringContext } from "../../../contexts/QueryStringContext";
import SimpleA from "../SimpleA";
import Consts from "../../../config/Consts";

const CLIPPING_ARTICLE = gql`
  mutation (
    $token: ID,
    $mode: Int,
    $user_id: ID,
    $article_id: ID,
    $remove_article_ids: [ID]
  ) {
    user_clipping(
      token: $token,
      mode: $mode,
      user_id: $user_id,
      article_id: $article_id,
      remove_article_ids: $remove_article_ids
    ) {
      code
      result
      count
    }
  }
`;

const FavoriteBtn = ({
  post_client,
  article_id,
  is_top
}) => {
  const theme = useTheme();
  const { pathname, queryString } = useContext(QueryStringContext);
  const { userInfo } = useContext(UserContext);
  const { favoriteInfo, setFavoriteInfo } = useContext(FavoriteContext);
  const [clip] = useMutation(CLIPPING_ARTICLE, { client: post_client });
  const [count, setCount] = useState(0);

  const popup_login = useRef();
  const popup_over = useRef();
  const popup = useRef();
  const btn = useRef();
  const btn_area = useRef();
  const [popupFlag, setPopupFlag] = useState(false);
  const [popupDlg, setPopupDlg] = useState(null);

  useEffect(() => {
    if(!popupDlg && userInfo.set === 1) {
      const popup_dlg = () => {
        if (userInfo.status === 2) {
          // 日経マガジン会員
          return(
            <div className="m-favorite-popup__over" ref={popup_over}>
              <div className="m-favorite-popup__over-close" onClick={() => {
                popup_over.current.classList.remove('is-show');
              }}></div>
              <p className="m-favorite-popup__over-text">上限に達しています</p>
              <SimpleA href="/favorite" className="m-favorite-popup__over-btn">記事の削除はこちらから</SimpleA>
            </div>
          )
        } else if (userInfo.status === 1) {
          return(
            <div className="m-favorite-popup__login" ref={popup_login}>
              <div className="m-favorite-popup__login-close" onClick={() => {
                popup_login.current.classList.remove('is-show');
              }}></div>
              <p className="m-favorite-popup__login-text">会員登録すると記事を<br className="is-pc" />保存することができます</p>
              <div className="m-favorite-popup__login-btn" onClick={() => {
                clickRegistration()
              }}>会員登録</div>
            </div>
          )
        } else {
          return(
            <div className="m-favorite-popup__login" ref={popup_login}>
              <div className="m-favorite-popup__login-close" onClick={() => {
                popup_login.current.classList.remove('is-show');
              }}></div>
              <p className="m-favorite-popup__login-text">ログインすると記事を<br className="is-pc" />保存することができます</p>
              <div className="m-favorite-popup__login-btn" onClick={() => {
                clickLogin()
              }}>ログイン/新規会員登録</div>
            </div>
          )
        }
      }
      setPopupDlg(popup_dlg())
    }
    if(favoriteInfo === null) {
      const variables = {
        token: "FavoriteBtn",
        mode: 0,
        user_id: userInfo.id,
        article_id: article_id
      }
      clip({ variables: variables }).then((val) => {
        if (val.data.user_clipping){
          if(val.data.user_clipping.code === 2) {
            btn_area.current.classList.add('is-inactive');
          } else {
            btn_area.current.classList.remove('is-inactive');
          }
          if(val.data.user_clipping.code === 0) {
            setFavoriteInfo(val.data.user_clipping.result === "true")
            setCount(val.data.user_clipping.count)
          }
        }
      });
    }
    if(favoriteInfo !== null) {
      if(favoriteInfo) {
        btn.current.classList.add('is-add');
        popup.current.classList.add('is-add');
      } else {
        btn.current.classList.remove('is-add');
        popup.current.classList.remove('is-add');
      }
      if(popupFlag) {
        popup.current.classList.add('is-show');
        setTimeout(function () {
          popup.current.classList.remove('is-show'); //2秒後にポップアップメッセージを非表示
          setPopupFlag(false);
        }, 2000);
      }
    }
  }, [userInfo, article_id, favoriteInfo, setFavoriteInfo, clip, popupFlag]);

  const clickFavorite = (() => {
    if (userInfo.status === 2) {
      // member
      if(!favoriteInfo && count >= 50) {
        // max articles !
        popup_over.current.classList.add('is-show');
      } else {
        const variables = {
          token: "FavoriteBtn",
          user_id: userInfo.id
        }
        if(favoriteInfo) {
          variables.mode = 2
          variables.remove_article_ids = [article_id]
        } else {
          variables.mode = 1
          variables.article_id = article_id
        }
        clip({ variables: variables }).then((val) => {
          if (val.data.user_clipping && val.data.user_clipping.code === 0) {
            setFavoriteInfo(!favoriteInfo)
            setPopupFlag(true)
          }
        }).catch((e) => {
          // has exception
        });
      }
    } else {
      // nonmember
      popup_login.current.classList.add('is-show');
    }
  });

  const clickLogin = (() => {
    const nonce = uuidv4();
    window.location.href = generateNikkeiIdMemberLoginUrl(pathname, queryString, nonce)
  });

  const clickRegistration = (async () => {
    await reqClearKbn();
    window.location.href = Consts.REACT.AUTH_IDRP_BASE_URL + '/reg/ai/frauth/start/'
  });

  if(is_top) {
    return (
      <div className={`p-${theme.page}__top-favorite m-favorite is-inactive`} ref={btn_area}>
        <div className="m-btn-favorite m-favorite__btn-favorite" ref={btn} onClick={() => {
          clickFavorite()
        }} role="button" data-atlas-trackable="article_favorite_1"></div>
        {popupDlg}
        <div className="m-favorite-popup__add" ref={popup}></div>
      </div>
    )
  } else {
    return (
      <li className={`p-${theme.page}__share-list-item u-favorite m-favorite is-inactive`} ref={btn_area}>
        <div id={`p-${theme.page}__share-btn-favorite`} className={`p-${theme.page}__share-btn-favorite m-favorite__btn-favorite`} onClick={(e) => {
          clickFavorite();
        }} role="button" data-atlas-trackable="article_favorite_2">
          <div className="m-favorite-balloon" ref={btn}><span className="m-favorite-balloon__message"></span></div>
        </div>
        {popupDlg}
        <div className="m-favorite-popup__add" ref={popup}></div>
      </li>
    )
  }
};

export default FavoriteBtn;