import React, { useRef, useEffect } from "react";

import HeaderLeft from '../HeaderLeft';
import HeaderRight from '../HeaderRight';
import useScrollPosition from "../../../hooks/useScrollPosition";
import SimpleA from "../../atoms/SimpleA";

const Header = ({
  isTop=false,
  post_client
}) => {
  const headerRef = useRef();
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    //日経ヘッダー分スクロールしたらTNMヘッダー固定
    if (scrollPosition > 37) {
      //ヘッダーにis-fixedクラス付加
      headerRef.current.classList.add('is-fixed');
    } else {
      //ヘッダーのis-fixedクラス削除
      headerRef.current.classList.remove('is-fixed');
    }
  }, [scrollPosition]);

  return (
    <header id="header" className="header" ref={headerRef}
      data-testid="block-name-header">
      <div className="header__inner">
        <HeaderLeft headerRef={headerRef} />
        <div className="header__center">
          <div className="header__logo">
            {isTop ?
              <h1><SimpleA href="/" data-atlas-trackable="header_logo"><img src="/images/common/header_logo@2x.png" alt="THE NIKKEI MAGAZINE" /></SimpleA></h1>
            :
              <SimpleA href="/" data-atlas-trackable="header_logo"><img src="/images/common/header_logo@2x.png" alt="THE NIKKEI MAGAZINE" /></SimpleA>
            }
          </div>
        </div>
        <HeaderRight post_client={post_client} />
      </div>
      <div className="header-stub"></div>
    </header>
  )
};

export default Header;